import React, { useCallback, useEffect, useRef } from 'react';
import { NotificationManager, useSettings } from '@apollo/core';
import { useHistory } from 'react-router-dom';
import BasePopup from './BasePopup';
import { useParticleAnimation } from '../../features/Animations/ParticleAnimation';
import { isUrlAbsolute } from '../../core/utils';

const TemplatePopup = ({ id, status, title, data }) => {
  const history = useHistory();
  const { isCordova } = useSettings();
  const { animationPrepared, runParticleAnimation, stopParticleAnimation } = useParticleAnimation();
  const modalContent = useRef(null);

  const handleContinueButtonClick = React.useCallback(() => {
    NotificationManager.removeNotification(id);
    stopParticleAnimation();
  }, [id, stopParticleAnimation]);

  const handleAnchorClick = useCallback(
    (event) => {
      if (!isCordova) {
        return;
      }
      const anchor = event.currentTarget;
      const target = anchor.getAttribute('target');
      const href = anchor.getAttribute('href');

      if (!isUrlAbsolute(href)) {
        event.preventDefault();
        NotificationManager.removeNotification(id);
        history.push(href);
        return false;
      }

      if (target && target.toLocaleLowerCase() == '_blank') {
        event.preventDefault();
        window.open(href, '_system');
        return false;
      }
    },
    [isCordova, id],
  );

  useEffect(() => {
    if (animationPrepared) {
      runParticleAnimation();
    }
  }, [animationPrepared]);

  useEffect(() => {
    const anchors = modalContent.current.getElementsByTagName('a');
    for (let i = 0; i < anchors.length; i += 1) {
      anchors[i].addEventListener('click', handleAnchorClick.bind(this), false);
    }
  }, [data.htmlContent, handleAnchorClick]);

  return (
    <BasePopup status={status} onClose={handleContinueButtonClick} title={title} icon={status}>
      <div ref={modalContent} dangerouslySetInnerHTML={{ __html: data.htmlContent }} />
    </BasePopup>
  );
};
export default TemplatePopup;
